import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import {
  Banks,
  HeroBot,
  HeroMid,
  HeroTop,
  IconBonus,
  IconCall,
  IconCashback,
  IconFunny,
  IconPhone,
  IconRich,
  LoginButton,
  Logo,
  Providers,
  RegisterButton,
} from "src/assets";
import { FloatingBanner } from "src/main/components";
import { AppContext } from "src/main/constants";
import { createStyles, isMobile } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        window.open(loginUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      } else {
        window.open(registerUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      }
    },
    [loginUrl, registerUrl],
  );

  const mk69Text = (
    <Typography
      color="#ff00ff"
      display="inline"
    >
      MK
      <Typography
        color="#fff"
        display="inline"
      >
        69
      </Typography>
    </Typography>
  );

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">MK69</VisuallyHidden>

      <Typography
        fontSize={32}
        fontWeight={700}
        className="gradient-text"
      >
        แพลตฟอร์มอันดับ 1
      </Typography>

      <Typography
        fontSize={16}
        fontWeight={400}
      >
        มั่นคง ปลอดภัย ถอนง่าย โอนไว
      </Typography>

      <Stack
        alignItems="center"
        justifyContent="center"
        gap={3}
        my={2}
        zIndex={100}
      >
        <img
          alt="logo"
          src={Logo}
          width={280}
        />

        <Stack
          direction="row"
          gap={2}
        >
          <a
            href={loginUrl}
            onClick={handleClick("login")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="login button"
              src={LoginButton}
              width={130}
            />
          </a>

          <a
            href={registerUrl}
            onClick={handleClick("register")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="register button"
              src={RegisterButton}
              width={130}
            />
          </a>
        </Stack>
      </Stack>

      <Box sx={styles.hero}>
        <img
          alt="hero bot"
          src={HeroBot}
          width={246}
          style={{ top: "4px", left: "70px", animation: "heroBot 1.2s ease-in infinite" }}
        />
        <img
          alt="hero mid"
          src={HeroMid}
          width={340}
          style={{ top: "179px", left: "10px", animation: "heroMid 1.2s ease-in infinite" }}
        />
        <img
          alt="hero top"
          src={HeroTop}
          width={307}
          style={{ top: "239px", left: "26px", animation: "heroTop 1.2s ease-in infinite" }}
        />
      </Box>

      <Stack
        alignItems="center"
        justifyContent="center"
        gap={2}
        my={2}
        px={2}
        sx={{
          "& p": {
            fontSize: "12px",
            fontWeight: "400",
          },
        }}
        color="#D8C9CC"
      >
        <img
          alt="logo"
          src={Logo}
          width={140}
        />

        <Typography textAlign="center">
          ยินดีต้อนรับสู่ {mk69Text} แพลตฟอร์มเกมคาสิโนออนไลน์ที่สนุกและน่าเชื่อถือที่สุด!
        </Typography>

        <Typography textAlign="center">
          หากคุณกำลังมองหาประสบการณ์การเล่นเกมคาสิโนออนไลน์ที่น่าเล่นและน่าสนใจ คุณมาถูกที่แล้ว ที่ {mk69Text}
          เรามุ่งมั่นที่จะให้ผู้เล่นทุกคนได้รับประสบการณ์ที่ยอดเยี่ยมที่สุด ไม่ว่าคุณจะเป็นมือใหม่หรือมืออาชีพ
          คุณจะพบกับความสนุกและความตื่นเต้นที่ไม่เหมือนใคร!
        </Typography>

        <Typography textAlign="center">ทำไมต้องเลือก {mk69Text}?</Typography>

        <Stack
          direction="row"
          gap={3}
        >
          <Stack
            justifyContent="space-between"
            gap={1}
            sx={{
              "& img": {
                width: "40px",
              },
            }}
          >
            <img
              src={IconFunny}
              alt="icon funny"
            />
            <img
              src={IconRich}
              alt="icon rich"
            />
            <img
              src={IconPhone}
              alt="icon phone"
            />
            <img
              src={IconBonus}
              alt="icon bonus"
            />
            <img
              src={IconCashback}
              alt="icon cashback"
            />
            <img
              src={IconCall}
              alt="icon call"
            />
          </Stack>

          <Stack
            gap={2}
            sx={{
              "& p": {
                textAlign: "left",
              },
            }}
          >
            <Typography>
              สนุกและน่าเชื่อถือ:
              <br />
              เราได้รับการยอมรับจากผู้เล่นทั่วโลกว่าเป็นแพลตฟอร์มที่มั่นคงและน่าเชื่อถือที่สุด
              ด้วยการออกแบบเกมที่น่าตื่นเต้นพร้อมกับระบบรักษาความปลอดภัยที่ทันสมัย
            </Typography>

            <Typography>
              ยิ่งเล่นยิ่งรวย: {mk69Text} มีเกมคาสิโนที่หลากหลาย
              <br />
              รวมถึงเกมสล็อตที่มีโอกาสชนะสูงและแจ็คพอตใหญ่โต ทำให้คุณมีโอกาสรวยขึ้นในเวลาอันสั้น
            </Typography>

            <Typography>
              นเตอร์เฟสที่หรูหราและใช้งานง่าย:
              <br />
              เว็บไซต์ของเราออกแบบมาให้ใช้งานง่ายและเข้าถึงได้ทุกแพลตฟอร์ม ไม่ว่าคุณจะเล่นบนคอมพิวเตอร์ แท็บเล็ต
              หรือมือถือ ไม่ว่าคุณจะอยู่ที่ไหนก็ตาม คุณก็สามารถเพลิดเพลินกับเกมของเราที่ {mk69Text}{" "}
              ได้อย่างไม่มีข้อจำกัด
            </Typography>

            <Typography>
              โบนัสมากมายและของแถมที่น่าสนใจ: {mk69Text}
              <br />
              เรามีโปรโมชั่นและโบนัสที่หลากหลายสำหรับสมาชิกใหม่และสมาชิกเก่า รวมถึงของแถมที่น่าสนใจที่คุณจะต้องไม่พลาด
            </Typography>

            <Typography>
              เว็บไซต์มั่นคง ฝาก-ถอนไว: ระบบฝาก-ถอนของเรารวดเร็วและปลอดภัย คุณสามารถทำธุรกรรมได้ตลอด 24
              ชั่วโมงโดยไม่ต้องรอนานที่ {mk69Text}
            </Typography>
          </Stack>
        </Stack>

        <img
          alt="providers"
          src={Providers}
          width={240}
        />

        <Typography textAlign="center">
          อย่ารอช้า! มาเป็นส่วนหนึ่งของครอบครัว {mk69Text}
          <br />
          และเริ่มต้นการเดินทางสู่ความมั่งคั่งพร้อมกับความสนุกที่ไม่มีที่สิ้นสุด สมัครสมาชิกวันนี้และรับโบนัสพิเศษทันที!
        </Typography>

        <img
          alt="banks"
          src={Banks}
          width={320}
        />
      </Stack>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "20px",
    pb: "100px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    maxWidth: "560px",
    margin: "0 auto",
  },
  hero: {
    marginTop: "-40px",
    position: "relative",
    width: "360px",
    height: "460px",
    img: {
      position: "absolute",
    },
  },
});

export default LandingPage;
